import * as React from 'react';
import { useRef, useState, useEffect } from 'react';
import { Router, useLocation, Route, Link, Switch, BaseLocationHook } from "wouter";
import styled, { css } from 'styled-components';

import AudioSRC from 'url:./../assets/claves-angelicae.ogg';
import { findMatchingRequiredOptions } from 'web3modal';
import speaker from "./../assets/speaker.svg";

const AudioControl = styled.div`
    position:absolute;
    top:0.5em;
    width:0.4em;
    display:block;
    marging:0;
    img {
        width:1em;
        height:1em;
    }
`;

export default function AudioPlayer (props:React.ComponentProps<any>) {

    const [startAudio, setStartAudio] = useState(false);
    const [mute, setMute] = useState(false);
    const audio:any = useRef(null);
    const [forceMute, setforceMute] = useState(false);

    const onInteraction = (e:Event) => {
        if (!startAudio) {
            setStartAudio(true);
        }
    }

    const toggleMute = () => {
        setMute(!mute);
        setforceMute(!mute);
    }

    function fadeOut(){
        var actualVolume = audio.current.volume;
        var fadeOutInterval = setInterval(function(){
            actualVolume = (parseFloat(actualVolume) - 0.1).toFixed(1);
            if(actualVolume >= 0) {
                audio.current.volume = actualVolume;
            } else {
                audio.current.pause();
                clearInterval(fadeOutInterval);
            }
        }, 100);
    }

    function fadeIn() {
        var actualVolume:number = 0;
        audio.current.play();
        var fadeInInterval = setInterval(function() {
            actualVolume = parseFloat((actualVolume + 0.1).toFixed(1));
            if(actualVolume <= 0.33) {
                audio.current.volume = actualVolume;
            } else {
                clearInterval(fadeInInterval);
            }
        }, 100);
    }

    useEffect(() => {
        if (audio.current) {
            if (mute) {
                fadeOut();
            } else {
                fadeIn();
            }
        }
    }, [mute]);

    useEffect(() => {
        window.addEventListener('click', onInteraction);
        window.addEventListener('touchstart', onInteraction);
    }, [])

    useEffect(() => {
        if (!forceMute) {
            setMute(props.mute);
        }
    }, [props.mute])

    useEffect(() => {
        if (startAudio) {
            audio.current = new Audio(AudioSRC);
            audio.current.volume = 0.33;
            audio.current.loop = true;
            audio.current.play();
        }
    }, [startAudio])

    return(
        <AudioControl style={{ left: props.currentLocation == "/" ? "0.5em" : "130px", opacity: mute ? "0.5" : "1" }}>
            <button className="nav" onClick={toggleMute}>{mute ? "Unmute" : "Mute"}</button>
        </AudioControl>
    );
}