import * as React from 'react';

export default function Container (props:React.ComponentProps<any>) {
    return (
        <div id="Container" className={props.className}>
            {props.title && (<h1>{props.title}</h1>)}
            <div id="ActiveArea">
                {props.children}
            </div>
        </div>
    );
}
