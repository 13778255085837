import * as React from 'react';
import { Component, useState, useEffect } from 'react';
import { useLocation } from 'wouter';
import styled, { css } from 'styled-components';
import AudioReactRecorder, { RecordState } from 'audio-react-recorder';

import Incant1 from './../../assets/incant_1.svg';
import Incant1w from './../../assets/incant_1w.svg';
import Incant2 from './../../assets/incant_2.svg';
import Incant2w from './../../assets/incant_2w.svg';
import Incant3 from './../../assets/incant_3.svg';
import Incant3w from './../../assets/incant_3w.svg';

import Container from "../Container";
import DotNav from "../DotNav";

const dotSize = 77;
const dotUnits = "px";
const Dots = styled.div`
    max-width:60%;
    min-width: 300px;
    height: ${dotSize + dotUnits};
    display: flex;
    justify-content: space-between;
    margin:2em auto 2em auto;
`;
const Dot = styled.div`
    position:relative;
    width:${dotSize+dotUnits};
    div.dd {
        position:relative;
        top: -${dotSize/1.8 + dotUnits};
        left: ${dotSize/2 + dotUnits};
    }
    .standin {
    }
    & > div.n {
        position:absolute;
        top:${dotSize + dotUnits};
        width:100%;
        padding-top:0.6em;
        text-align:center;
    }
`;
const RecordingArea = styled.div`
    width:100%;
    margin-top:3em;
    position:relative;
    height:7em;
`;
const Word = styled.div`
    height:1.5em;
    h2 {
        font-size:1.5rem;
        font-weight:bold;
        margin:0.5em;
    }
`;
const Instructions = styled.div`
    margin-top:1em;
    font-size:1em;
    height:1em;
`;


export default function Logos (props:React.ComponentProps<any>) {

    const [location, setLocation] = useLocation();
    const [audioEnabled, setAudioEnabled] = useState(true);
    const [fadeTransition, setFadeTransition] = useState("fadeIn");
    const [incant1Visible, setIncant1Visible] = useState(true);
    const [incant2Visible, setIncant2Visible] = useState(false);
    const [incant3Visible, setIncant3Visible] = useState(false);
    const [instructions, setInstructions] = useState("Open a channel. Incant your Word.");
    const [wordVisible, setWordVisible] = useState(false);
    const [recordState, setRecordState] = useState(RecordState.NONE);

    const [recorderWidth, setRecorderWidth] = useState(600);
    const recordingTimeout = 4000;

    const updateRecorderWidth = () => {
        setRecorderWidth(Math.max(Math.min(window.innerWidth - 50, 600), 250));
    };

    useEffect(() => {

        // test if mic is enabled
        navigator.mediaDevices.getUserMedia({
            audio: true
        })
        .then(stream => setAudioEnabled(true))
        .catch(err => setAudioEnabled(false));

        updateRecorderWidth();
        window.addEventListener("resize", updateRecorderWidth);
        return () => window.removeEventListener("resize", updateRecorderWidth);
    }, []);

    const incant1Clicked = () => {
        props.onIncantBegin();
        setInstructions("Now, speak your Word aloud.");
        setWordVisible(true);
        if (audioEnabled) setRecordState(RecordState.START);
        setTimeout(() => {
            if (audioEnabled) setRecordState(RecordState.STOP);
            setIncant2Visible(true);
            setInstructions("");
        }, recordingTimeout);
    }

    const incant2Clicked = () => {
        setInstructions("Speak it again.");
        if (audioEnabled) setRecordState(RecordState.START);
        setTimeout(() => {
            if (audioEnabled) setRecordState(RecordState.STOP);
            setInstructions("");
            setIncant3Visible(true);
        }, recordingTimeout);
    }

    const incant3Clicked = () => {
        setInstructions("And one final time.");
        if (audioEnabled) setRecordState(RecordState.START);
        setTimeout(() => {
            if (audioEnabled) setRecordState(RecordState.STOP);
            nextScene();
        }, recordingTimeout);
    }

    const nextScene = () => {
        const advanceScene = () => {
            props.onIncantEnd();
            setFadeTransition("fadeOut");
            setTimeout(() => {
                setLocation("/v")
            }, 500);
        }
        // close audio streams
        if (audioEnabled) {
            navigator.mediaDevices.getUserMedia({audio: true})
            .then((stream) => {
                stream.getTracks().forEach(track => {
                    track.stop();
                    track.enabled = false;
                })
            })
            .then(() => {
                advanceScene();
            });
        } else {
            advanceScene();
        }
    }

    const onStop = (audioData:object) => {
        console.log("audioData", audioData);
    }

    return(
        <>
        <Container title="iv. Incantation" className={fadeTransition}>

            <p>
                The Word is twofold: internal and uttered. An uttered word is the internal
                Word brought forth through breath into vibration. Through this act of locution
                the Will sets the Word in motion.
            </p>
            <p>
                Incantation is derived from the Latin <em>incantare</em> meaning &ldquo;to enchant,
                cast a spell.&rdquo; The infamous magical word <em>Abracadabra</em> is derived
                from the Aramaic meaning &ldquo;I create as I speak.&rdquo;
            </p>
            <p>
                Language operates as the material mediation between subconscious desires
                and the conscious mind. The Word is the Will encoded into a substrate for
                transmission.
            </p>

            <AudioReactRecorder
                state={recordState}
                onStop={onStop}
                foregroundColor="#000"
                backgroundColor="#fff"
                canvasWidth={recorderWidth}
                canvasHeight="120" />
            <Word>
                <h2>&nbsp;{wordVisible && (<>&ldquo;{props.word}&rdquo;</>)}&nbsp;</h2>
            </Word>
            <Instructions>
                &nbsp;{instructions}&nbsp;
            </Instructions>
            <Dots className="fadeInSlow">
                <Dot>
                    <img className="standin" src={Incant1w} />
                    <div className="n">i.</div>
                    <div className="dd">
                        <DotNav src={Incant1} visible={incant1Visible} onClick={incant1Clicked} />
                    </div>
                </Dot>
                <Dot>
                    <img className="standin" src={Incant2w} />
                    <div className="n">ii.</div>
                    <div className="dd">
                        <DotNav src={Incant2} visible={incant2Visible} onClick={incant2Clicked} />
                    </div>
                </Dot>
                <Dot>
                    <img className="standin" src={Incant3w} />
                    <div className="n">iii.</div>
                    <div className="dd">
                        <DotNav src={Incant3} visible={incant3Visible} onClick={incant3Clicked} />
                    </div>
                </Dot>
            </Dots>

        </Container>
        </>
    );
}