import * as React from 'react';
import { Component, useEffect, useState } from 'react';
import { Link, useLocation } from "wouter";
import styled, { css } from 'styled-components';

import * as CryptoJS from "crypto-js";

import Container from "../Container";
import DotNav from "../DotNav";


const translationMatrix = `a	א	α	1	🝛	🜁	🜂	🜃	🜇
b	ב	β	2	🜐	🜑	🜒	🜓	🜜
c	ח	ξ	8	🜠	🜡	🜢	🜣	🝌
d	ד	δ	4	🜰	🜱	🜲	🜳	🜼
e	ע	ε	70	🝑	🝒	🝗	🝋	🜬
f	פ	φ	80	🜅	🝡	🝝	🝓	🝝
g	ג	γ	3	🝠	🝀	🝢	🝂	🝭
h	ה		5	🝰		🝲	🝳	🝮
i	י	η	0	🜄	🝐	🝯	🜞	🜺
j	ג		3	🜔	🜕	🜖	🜗	🜏
k	כ	κ	20	🜤	🜥	🜦	🜧	🜎
l	ל	λ	30	🜴	🜵	🜶	🜫	🜛
m	מ	μ	40	🜫	🜝	🝫	🜨	🜨
n	נ	ν	50	🝤	🝥	🝦	🝧	🜪
o		ω	0	🝩	🝪	🝖	🜺	🝪
p	פ	π	80	🜘	🜻	🜻	🜛	🝩
q	ק		100	🜀	🜩	🜪	🜫	🜩
r	ר	ρ	200	🝈	🜹	🜆	🜻	🝈
s	ס	ς	60	🜎	🝉	🝜	🝋	🝉
t	ת	θ	400	🜾	🜿	🜊	🜋	🝊
u		υ	6	🝣	🝝	🝞	🝟	🝁
v	ו	β	6	🜈	🜉	🝎	🝏	🝬
w	וו		66	🝆	🝇	🝞	🜌	🝃
x		χ	400	🜯	🜮	🜟	🜞	🜝
y	י	υ	0	🜎	🜎	🝍	🜍	🜫
z	צ	ζ	7	🜭	🜽	🝟	🜝	🜻`;

const DotPos = styled.div`
    position:relative;
    margin-top:5em;
    left:50%;
    width:100px;
`;

const LogosInput = styled.div`
    margin:2em auto;
    max-width:80%;
    input {
        font-size:1.75em;
        text-align:center;
        padding:0.25em;
        font-family: "BM";
        width:90%;
    }
`;

const LogosOutput = styled.div`
    line-height: 1.5em;
    font-family: monospace;
    font-size: 0.75em;
    white-space: pre-wrap;
    word-wrap: break-word;
    padding:0 1em;
`;

const LogosIntro = styled.div``;
const LogosType = styled.div``;

export default function Logos (props:React.ComponentProps<any>) {

    const [location, setLocation] = useLocation();
    const [navVisible, setNavVisible] = useState(false);
    const [fadeTransition, setFadeTransition] = useState("fadeIn");
    const [encryptedOutputStr, setEncryptedOutputStr] = useState("");
    const [dotFade, setDotFade] = useState("fadeIn");
    const [logosIntroClass, setLogosIntroClass] = useState("");
    const [logosTypeClass, setLogosTypeClass] = useState("removed");

    const [word, setWord] = useState("");
    const [wordEncoded, setWordEncoded] = useState("");

    const [sha1, setSha1] = useState("");
    const [lang1, setLang1] = useState("");
    const [lang2, setLang2] = useState("");
    const [lang3, setLang3] = useState("");
    const [lang4, setLang4] = useState("");
    const [lang5, setLang5] = useState("");
    const [lang6, setLang6] = useState("");
    const [lang7, setLang7] = useState("");
    const [lang8, setLang8] = useState("");


    useEffect(() => {
        // select input html element on load
        let input = document.getElementById('textInput') as HTMLInputElement;
        input.select();
        // setWordEncoded("");
    }, []);

    useEffect(() => {
        // process word on input
        const encoded = encryptString(word);

        // hide dot if no word
        setNavVisible(word.length > 0);
        setDotFade(word.length > 0 ? "fadeIn" : "fadeOut");

        // send word to App Context (parent) for processing in next scene
        if (props.onWordChange && typeof props.onWordChange == "function") {
            props.onWordChange(word, encoded);
        }

    }, [word]);

    const onChange = (e:any) => {
        setWord(e.target.value);
    }

    // encoded sha1 hash as 10 character string
    const encodeHash = (hashedStr:string) => {

        // splice string into chunks
        const spliceIntoChunks = (str:string, chunkSize:number) => {
            const arr = str.split('');
            let res = [];
            while (arr.length > 0) {
                const chunk = arr.splice(0, chunkSize);
                res.push(chunk.join(''));
            }
            return res;
        }

        // split hash string into char pairs
        const chunks = spliceIntoChunks(hashedStr, 2);
        const len = chunks.length;
        let output = "";

        // create new 10 char long string
        for (let i = 0; i < 10; i++) {

            // jump over every 10th char
            const pos = (i * 10) % (len - 1);

            // chunk position
            const c = chunks[pos];

            // convert to decimal
            const dec = parseInt(c, 16);

            let v:number;
            if (dec <= 57) { // if dec is <= 57
                v = (dec % 10) + 48; // force to ascii [0-9]
            }
            else { // else all other characters
                v = (dec % 26) + 97; // force to ascii [a-z]
            }

            output += String.fromCharCode(v);
        }

        return output;
    }


    const customLang = (langNum:number, str:string) => {

        let outStr = "";

        // map matrix into char arrays
        const matrix = translationMatrix.split(/\n/).map((v) => {
            return v.split(/\t/);
        });

        // split string into char array
        const arr = str.split('');
        for (var i = 0; i < arr.length; i++) {

            let SN = arr[i];

            // if char is a number, replace with space
            if (parseInt(SN) >= 0 || SN == ' ') {
                SN = ' '
                outStr += SN;
            }
            else {
                SN = SN.toLowerCase();

                // get index of character
                var index = matrix.map(arr => arr[0]).indexOf(SN);

                // use character of appropriate lang num
                if (index > 0)
                    outStr += matrix[index][langNum];
            }
        }

        return outStr;
    }


    const encryptString = (str:string) => {
        // sha1
        const sha1 = CryptoJS.SHA256(str).toString(CryptoJS.enc.Hex);
        setSha1(sha1);

        // encoded
        const encoded = encodeHash(sha1);
        setWordEncoded(encoded);

        // lang1
        setLang1(customLang(1, str));
        setLang2(customLang(2, str));
        setLang3(customLang(3, str));
        setLang4(customLang(4, str));
        setLang5(customLang(5, str));
        setLang6(customLang(6, str));
        setLang7(customLang(7, str));
        setLang8(customLang(8, str));

        return str.length > 0 ? encoded : "";
    }

    const EncryptedOutput = () => {
        return (
            <>

            </>
        );
    }

    const beginLogos = (e:React.MouseEvent) => {
        e.preventDefault();
        setLogosIntroClass("fadeOut");
        setTimeout(() => {
            setLogosIntroClass("removed");
            setLogosTypeClass("fadeInSlow");
        }, 500);
    }

    const nextScene = (e:React.MouseEvent) => {
        e.preventDefault();
        setFadeTransition("fadeOut");
        setTimeout(() => {
            setLocation("/iv");
        }, 500);
    }

    return(
        <>
        <Container title="iii. Logos" className={fadeTransition}>

            <p>
                &ldquo;In the beginning was the Word (<i>Logos</i>).&rdquo;
            </p>
            <p>
                A Word is twofold: internal and uttered. The internal Word is a conception of the mind, and motion of the soul, which is made without a voice. The <em>Logos</em> is the generative principle which impregnates potentiality.
            </p>
            <p>
                Give Name to your desire.
            </p>

            <LogosInput className="fadeInSlow">
                <input id="textInput" type="text" autoComplete="off" onChange={onChange} />
            </LogosInput>

            <LogosOutput className={word ? "fadeIn" : "fadeOut"}>
                {word && (
                <div>
                    {lang1}<br />
                    {lang2}<br />
                    {lang3}<br />
                    {lang7}<br />
                    {sha1}<br />
                    {wordEncoded}
                </div>
                )}
            </LogosOutput>

            <br />

            {navVisible && (
                <a className="nav" href="#" onClick={nextScene}>Continue</a>
            )}

        </Container>
        </>
    );
}
