import * as React from 'react';
import { Component, useState, useEffect } from 'react';
import { Link, useLocation } from "wouter";
import styled, { css } from "styled-components";

import Banishing_Cross from './../../assets/banishing_cross.svg';
import Banishing_Up from './../../assets/banishing_up.svg';
import Banishing_Right from './../../assets/banishing_right.svg';
import Banishing_Down from './../../assets/banishing_down.svg';
import Banishing_Left from './../../assets/banishing_left.svg';
import Banishing_Center from './../../assets/banishing_center.svg';
import Dot_Ring from './../../assets/dot_ring.svg';

import Container from "../Container";
import DotNav from "../DotNav";

const Cross = styled.div`
    width:100%;
    height:100%;
    position:relative;
    max-width:600px;
    margin:0 auto;
    padding:0 0.2em;
    opacity: 0;
`;
const dotSize = '16.75%';
const StandIn = styled.div`
    position:absolute;
    width:100%;
    top:-50%;
    left:-50%;
`;
const Up = styled.div`
    position:absolute;
    top: 16%;
    left: 50%;
    width:${dotSize};
    height:${dotSize};
`;
const Down = styled.div`
    position:absolute;
    top:83%;
    left:50%;
    width:${dotSize};
    height:${dotSize};
`;
const Right = styled.div`
    position:absolute;
    top:50%;
    right:0;
    width:${dotSize};
    height:${dotSize};
`;
const Left = styled.div`
    position:absolute;
    top:50%;
    left:16.5%;
    width:${dotSize};
    height:${dotSize};
`;
const Center = styled.div`
    position:absolute;
    top:50%;
    left:50%;
    width:${dotSize};
    height:${dotSize};
`;
const WordText = styled.div`
    margin-top: 1em;
    font-size: 1.5rem;
    font-family: "BMI";
`;

const Word = (props:React.ComponentProps<any>) => {
    return(props.visible &&
        <WordText className="fadeIn">
            {props.word}
        </WordText>
    )
}

export default function Banishing () {

    const [location, setLocation] = useLocation();
    const [fadeTransition, setFadeTransition] = useState("fadeIn");
    const [crossFade, setCrossFade] = useState("");

    const [upVisible, setUpVisible] = useState(false);
    const [downVisible, setDownVisible] = useState(false);
    const [leftVisible, setLeftVisible] = useState(false);
    const [rightVisible, setRightVisible] = useState(false);
    const [centerVisible, setCenterVisible] = useState(false);
    const [standInVisible, setStandInVisible] = useState(false);

    useEffect(() => {
        const i = new Image();
        i.src = Banishing_Cross;
        i.onload = (() => {
            setCrossFade("fadeInSlow");
            setUpVisible(true);
        });
    }, []);

    const upClicked = (e:React.MouseEvent) => {
        setUpVisible(false);
        setDownVisible(true);
    }

    const downClicked = (e:React.MouseEvent) => {
        setDownVisible(false);
        setLeftVisible(true);
    }

    const leftClicked = (e:React.MouseEvent) => {
        setLeftVisible(false);
        setRightVisible(true);
    }

    const rightClicked = (e:React.MouseEvent) => {
        setRightVisible(false);
        setCenterVisible(true);
    }

    const centerClicked = (e:React.MouseEvent) => {
        setCenterVisible(false);
        setStandInVisible(true);
        setTimeout(() => {
            setFadeTransition("fadeOut");
            setTimeout(() => {
                setLocation("/iii");
            }, 500);
        }, 800);
    }

    return(
        <>
        <Container title="ii. Banishing" className={fadeTransition}>

            <p>
                Within each self there is a natural fortress: the Soul impregnable.
                However beyond this central citadel, one also has outerworks which are
                vulnerable and susceptible to violation.
            </p>
            <p>
                Banishing rites are twofold: Firstly, it provides a cleansing and
                maintenance of the walls of the citadel. Secondly, it operates as a
                firewall for the body, fortifying and consecrating the Magus in a
                bastion away from exploits and malicious attacks.
            </p>

            <Word word="i. Ateh" visible={upVisible} />
            <Word word="ii. Malkuth" visible={downVisible} />
            <Word word="iii. ve-Geburah" visible={leftVisible} />
            <Word word="iv. ve-Gedulah" visible={rightVisible} />
            <Word word="v. le-Olahm. Amen" visible={centerVisible} />
            <Word word="&nbsp;" visible={standInVisible} />

            <br />

            <Cross className={crossFade}>
                <img src={Banishing_Cross} />
                <Up>
                    <StandIn><img className="standin" src={Dot_Ring} /></StandIn>
                    <DotNav src={Banishing_Up} visible={upVisible} onClick={upClicked} />
                </Up>
                <Down>
                    <StandIn><img className="standin" src={Dot_Ring} /></StandIn>
                    <DotNav src={Banishing_Down} visible={downVisible} onClick={downClicked} />
                </Down>
                <Left>
                    <StandIn><img className="standin" src={Dot_Ring} /></StandIn>
                    <DotNav src={Banishing_Left} visible={leftVisible} onClick={leftClicked} />
                </Left>
                <Right>
                    <StandIn><img className="standin" src={Dot_Ring} /></StandIn>
                    <DotNav src={Banishing_Right} visible={rightVisible} onClick={rightClicked} />
                </Right>
                <Center>
                    <StandIn><img className="standin" src={Dot_Ring} /></StandIn>
                    <DotNav src={Banishing_Center} visible={centerVisible} onClick={centerClicked} />
                </Center>
            </Cross>

             <br />

        </Container>
        </>
    );
}