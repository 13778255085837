import * as React from 'react';
import { Component, useEffect, useState, useRef } from 'react';
import { Link, useLocation } from "wouter";
import styled from "styled-components";

import Rosy_Cross from "./../../assets/rosy_cross.svg";
import Dot from './../../assets/dot_filled.svg';
import DotW from './../../assets/dot_filledW.svg';

import Container from "../Container";
import DotNav from "../DotNav";

import * as rosyCross from '../../assets/rosy_cross.json';

const Cross = styled.div`
    position:relative;
    width:100%;
    height:100%;
`;
const dotSize = "17%";
const DotPlacement = styled.div`
    position:absolute;
    top:0;
    left:0;
    width: ${dotSize};
    max-width:100px;
    & > img {
        position:relative;
        left:-50%;
    }
`;
const CanvasPlacement = styled.div`
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    canvas {
        width:100%;
        height:100%;
    }
`;

export default function Seal (props:React.ComponentProps<any>) {

    const [location, setLocation] = useLocation();

    const [curStrIndex, setCurStrIndex] = useState<number>(-1);
    const [curPosIndex, setCurPosIndex] = useState<number>(-1);
    const [fadeTransition, setFadeTransition] = useState("fadeIn");
    const [roseFade, setRoseFade] = useState("");
    const sigilVerts = useRef([]);

    useEffect(() => {
        const i = new Image();
        i.src = Rosy_Cross;
        i.onload = (() => {
            setRoseFade("fadeInSlow");
        });
    }, []);

    const canvasRef = useRef<HTMLCanvasElement>();

    const getDotIndexByStr = (char:string) => {
        return rosyCross.lookupTable.findIndex((d:string) => {
            return d.includes(char);
        });
    }

    const nextDot = () => {
        setCurStrIndex(curStrIndex + 1);
    }

    const renderDot = (index:number) => {
        const d = rosyCross.positions[index];
        const id = rosyCross.lookupTable[index];
        const key = id + Math.floor(Math.random() * 100).toString(32);
        const u:number = d[0] as number;
        const v:number = d[1] as number;
        return(
            <DotPlacement className="fadeIn" id={"dot_" + id} key={key} style={{left:u * 100 + "%", top: v * 100 + "%" }}>
                <DotNav src={Dot} visible={true} onClick={nextDot} />
                <img src={DotW} />
            </DotPlacement>
        );
    };

    const nextScene = () => {
        setTimeout(() => {
            setFadeTransition("fadeOut");
            setTimeout(() => {
                setLocation("/vi");
            }, 500);
        }, 1000);
    }

    useEffect(() => {
        if (props.wordEncoded) {
            setCurStrIndex(0);
            // create sigil verts array
            sigilVerts.current = props.wordEncoded.split('').map((c:string) => {
                return rosyCross.positions[getDotIndexByStr(c)];
            });
        }
    }, []);

    const lineWidth = 7;
    const drawSigil = () => {
        const canvas = canvasRef.current;
        if (canvas) {

            const c = canvas.getContext('2d');

            if (c) {

                const w = canvas.width;
                const h = canvas.height;

                let animate = true;

                // set point position and destination
                let px:number = NaN,
                    py:number = NaN,
                    dx:number = NaN,
                    dy:number = NaN;
                let r:number = 40;
                if (curStrIndex - 2 >= 0) {
                    px = sigilVerts.current[curStrIndex - 2][0];
                    py = sigilVerts.current[curStrIndex - 2][1];
                    dx = sigilVerts.current[curStrIndex - 1][0];
                    dy = sigilVerts.current[curStrIndex - 1][1];
                }

                const animatePath = () => {

                    // interpolate
                    if (px && py) {
                        px += (dx - px) * 0.1;
                        py += (dy - py) * 0.1;
                    }
                    r += (lineWidth/2 - r) * 0.1;

                    // draw sigil path
                    c.clearRect(0, 0, canvas.width, canvas.height);
                    c.lineWidth = lineWidth;
                    c.beginPath();
                    for (let i = 0; i <= curStrIndex; i++) {
                        const uv = sigilVerts.current[i-1];
                        if (uv) {
                            const x:number = uv[0] * w;
                            const y:number = uv[1] * h;
                            if (i == 0) {
                                c.moveTo(x, y);
                            }
                            else if (i == curStrIndex) {
                                c.lineTo(px * w, py * h);
                            }
                            else {
                                c.lineTo(x, y);
                            }
                        }
                    }
                    c.stroke();

                    // draw animated dot
                    if (curStrIndex-1 >= 0) {
                        c.beginPath();
                        const rx = sigilVerts.current[curStrIndex-1][0];
                        const ry = sigilVerts.current[curStrIndex-1][1];
                        c.arc(rx * w, ry * h, r, 0, 2 * Math.PI);
                        c.fill();
                    }

                    // only animate if radius of dot is still animating
                    if (r - lineWidth/2 > 0.01) {
                        requestAnimationFrame(animatePath);
                    }
                }

                if (animate) {
                    animatePath();
                }
            }
        }
    }

    useEffect(() => {
        if (curStrIndex >= 0) {
            const lookup = props.wordEncoded.charAt(curStrIndex);
            if (lookup) {
                const index = getDotIndexByStr(lookup);
                setCurPosIndex(index);
            }
            else {
                setCurPosIndex(-1);
                nextScene();
            }
            drawSigil();
        }
    }, [curStrIndex]);

    const RenderLabels = () => {
        return rosyCross.positions.map((dd:any, k:number) => {
            const d = dd;
            const id = rosyCross.lookupTable[k];
            const u:number = d[0] as number;
            const v:number = d[1] as number;
            return(
                <DotPlacement id={"dot_" + id} key={id} style={{left:u * 95 + "%", top: v * 95 + "%" }}>
                    {id}
                </DotPlacement>
            );
        })
    }

    return(
        <>
        <Container title="v. Seal" className={fadeTransition}>

            <p>
                A sigil is the encoding of desire or intent into a symbol.
            </p>
            <p>
                The Latin <em>sigillum</em> means &lsquo;seal&rsquo;. It is derived from the Hebrew <span style={{fontFamily:"sans-serif", padding:"0 0 0 0.25em"}}>הלוגס</span> (<em>segula</em>) meaning &lsquo;word, action, or talisman&rsquo;.
            </p>
            <p>
                The sigil traces the paths of the majestic alphabet of the ancient magi with the Rosy Cross as its key.
            </p>
            <p>
                This seal prepares the Word for transmission onto the Ethereum network.
            </p>

            <Cross className={roseFade}>
                <img src={Rosy_Cross} />
                <CanvasPlacement>
                    <canvas ref={canvasRef as any} width="600" height="600" />
                </CanvasPlacement>
                {curPosIndex >= 0 && renderDot(curPosIndex)}
            </Cross>

            {/* {RenderLabels()} */}

        </Container>
        </>
    );
}
