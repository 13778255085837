{
    "lookupTable" : [
        "h5",
        "q",
        "iou",
        "e",
        "s",
        "n",
        "l",
        "y0",
        "9",
        "c8",
        "z7",
        "vw6",
        "k",
        "t",
        "gj3",
        "d4",
        "b2",
        "r",
        "pf",
        "a1",
        "sh",
        "m",
        "x"
    ],
    "positions" : [
        [ 0.500, 0.140 ],
        [ 0.689, 0.186 ],
        [ 0.814, 0.318 ],
        [ 0.860, 0.501 ],
        [ 0.814, 0.682 ],
        [ 0.689, 0.814 ],
        [ 0.500, 0.865 ],
        [ 0.315, 0.814 ],
        [ 0.188, 0.682 ],
        [ 0.140, 0.501 ],
        [ 0.185, 0.318 ],
        [ 0.313, 0.186 ],
        [ 0.620, 0.265 ],
        [ 0.760, 0.445 ],
        [ 0.707, 0.665 ],
        [ 0.500, 0.763 ],
        [ 0.293, 0.665 ],
        [ 0.250, 0.445 ],
        [ 0.380, 0.265 ],
        [ 0.500, 0.340 ],
        [ 0.644, 0.585 ],
        [ 0.380, 0.570 ],
        [ 0.500, 0.500 ]
    ]
}
