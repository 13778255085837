{
    "name": "web3",
    "version": "1.6.1",
    "description": "Ethereum JavaScript API",
    "repository": "https://github.com/ethereum/web3.js",
    "license": "LGPL-3.0",
    "engines": {
        "node": ">=8.0.0"
    },
    "main": "lib/index.js",
    "bugs": {
        "url": "https://github.com/ethereum/web3.js/issues"
    },
    "keywords": [
        "Ethereum",
        "JavaScript",
        "API"
    ],
    "author": "ethereum.org",
    "types": "types/index.d.ts",
    "scripts": {
        "compile": "tsc -b tsconfig.json",
        "dtslint": "dtslint --localTs ../../node_modules/typescript/lib types",
        "postinstall": "echo \"WARNING: the web3-shh and web3-bzz api will be deprecated in the next version\""
    },
    "authors": [
        {
            "name": "Fabian Vogelsteller",
            "email": "fabian@ethereum.org",
            "homepage": "http://frozeman.de"
        },
        {
            "name": "Marek Kotewicz",
            "email": "marek@parity.io",
            "url": "https://github.com/debris"
        },
        {
            "name": "Marian Oancea",
            "url": "https://github.com/cubedro"
        },
        {
            "name": "Gav Wood",
            "email": "g@parity.io",
            "homepage": "http://gavwood.com"
        },
        {
            "name": "Jeffery Wilcke",
            "email": "jeffrey.wilcke@ethereum.org",
            "url": "https://github.com/obscuren"
        }
    ],
    "dependencies": {
        "web3-bzz": "1.6.1",
        "web3-core": "1.6.1",
        "web3-eth": "1.6.1",
        "web3-eth-personal": "1.6.1",
        "web3-net": "1.6.1",
        "web3-shh": "1.6.1",
        "web3-utils": "1.6.1"
    },
    "devDependencies": {
        "@types/node": "^12.12.6",
        "dtslint": "^3.4.1",
        "typescript": "^3.9.5",
        "web3-core-helpers": "1.6.1"
    },
    "gitHead": "3299240587db8dc3f0b2fc27aa973d218a83265b"
}
