import * as React from 'react';
import { useEffect, useState } from 'react';
import { useLocation } from "wouter";

import Container from "../Container";

export default function Casting (props:React.ComponentProps<any>) {

    const [fadeTransition, setFadeTransition] = useState("fadeIn");
    const [location, setLocation] = useLocation();

    useEffect(() => {
        if (props.mintTx) {
            setFadeTransition("fadeOut");
            setTimeout(() => {
                setLocation("/vii");
            }, 500);
        }
    }, [props.mintTx])


    return(
        <>
        <Container title="vi. Casting" className={fadeTransition}>

            <p>
                Prepare to cast your spell onto the Ethereum network.
            </p>

            <p>
                After casting, your sigil will be encircled by the transaction
                hash of your encrypted Word, minted into Ethereum’s distributed ledger.
            </p>

            {props.account.connected && (
                <>
                <br />
                {props.account.casting && (
                    <div className="nav loading">Casting Spell. Do not refresh. Waiting for Network.</div>
                ) || (
                    <button className="nav" onClick={props.castSigil}>Cast</button>
                )}
                </>
            ) || (
                <p>Connect wallet to cast.</p>
            )}

        </Container>
        </>
    );
}
