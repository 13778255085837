import * as React from 'react';
import { Component, useEffect, useState, useRef } from 'react';
import { render } from 'react-dom';
import styled, { css } from 'styled-components';

import DotPulseSvg from './../assets/dot_pulse2.svg';
import DotPulse2Svg from './../assets/dot_pulse.svg';
import EmptySrc from './../assets/dot_empty.svg';
import FilledSrc from './../assets/dot_filled.svg';
import { checkPropTypes } from 'prop-types';

const DotNavRing = styled.div`
    position:absolute;
    width:100%;
    height:100%;
`;

const DotHover = styled.div`
    position:absolute;
    width:100%;
    height:100%;
`;

export default function DotNav (props:React.ComponentProps<any>) {

    const mounted = useRef(false);

    const [clicked, setClicked] = useState(false);
    const [navClassName, setNavClassName] = useState("DotNav hidden");
    const [imgClassName, setImgClassName] = useState("image");
    const [pulseFadeClassName, setPulseFadeClassName] = useState("pulseWrapper");
    const [dotNavRingClassName, setDotNavRingClassName] = useState("dotNavRing");
    const [dotHoverClassName, setDotHoverClassName] = useState("dotHover");

    useEffect(() => {
        // linkRef.current.addEventListener();
    }, []);

    useEffect(() => {
        if (props.visible) {
            setNavClassName("DotNav visible");
        }
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
      }, [props.visible, clicked]);

    const onMouseOver = (e:React.MouseEvent) => {
        if (!clicked)
            setDotHoverClassName("dotHover over");
    }

    const onMouseOut = (e:React.MouseEvent) => {
        if (!clicked)
            setDotHoverClassName("dotHover out");
    }

    const onClick = (e:any) => {
        if (clicked) return;
        if (e.type == 'click') e.preventDefault();

        setClicked(true);
        setImgClassName("image clicked");
        setPulseFadeClassName("pulseWrapper clicked");
        setDotNavRingClassName("dotNavRing clicked");
        setDotHoverClassName("dotHover clicked");

        // set image class name to visible, which doesn't include animation
        // after animation is complete (defined in css as 500ms)
        // only do this if the component is still mounted
        setTimeout(() => {
            if (mounted.current) {
                setImgClassName("image visible");
            }
        }, 500);

        if (props.onClick && typeof props.onClick == "function")
            props.onClick(e);
    }

    const DotLink = (props:React.ComponentProps<any>) => {

        const Clicked = <span>
        <img src={props.src || EmptySrc} />
        </span>;

        const Link = <a href="#" onClick={onClick} onTouchStart={onClick}>
        <img src={props.src || EmptySrc} />
        </a>;

        return (
            <div className={imgClassName} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
                {clicked ? Clicked : Link}
            </div>
        );
    }

    return(
        <div className={navClassName}>
            <DotNavRing>
                <img className={dotNavRingClassName} src={DotPulse2Svg} />
            </DotNavRing>
            <DotHover>
                <img className={dotHoverClassName} src={FilledSrc} />
            </DotHover>
            <div className={pulseFadeClassName}>
                <div className="pulse">
                    <img src={DotPulseSvg} />
                </div>
            </div>
            <DotLink src={props.src} />
        </div>
    );
}