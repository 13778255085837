import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import { Link, useLocation } from "wouter";
import styled from "styled-components";

import Container from "./Container";

export default function DevsOnly (props:React.ComponentProps<any>) {

    const [fadeTransition, setFadeTransition] = useState("fadeIn");

    const [location, setLocation] = useLocation();

    const [authed, setAuthed] = useState(false);
    const [pwinput, pwSetInput] = useState("meta777");
    const [reservedTokenSpell, setReservedTokenSpell] = useState("");
    const [reservedtxHash, setReservedTxHash] = useState("");
    const [reservedTokenId, setReservedTokenId] = useState("");
    const [metadataLink, setMetadataLink] = useState(null);
    const [generating, setGenerating] = useState(false);

    useEffect(() => {
        setAuthed(false);
        pwSetInput("");
        setMetadataLink(null);
    }, []);

    const testAuth = () => {
        if (pwinput == process.env.DEVS_PASSWORD) {
            setAuthed(true);
        }
    }

    const generateMetadata = async (spellStr:string, tokenId:number, txHash:string) => {
        if (!spellStr || !tokenId || !txHash) {
            alert("Input all fields");
            return;
        }
        setGenerating(true);
        setMetadataLink(null);
        const metadata = await props.regenereateMetadata(spellStr, tokenId, txHash);
        if (metadata.res == 200) {
            setMetadataLink(metadata.uri);
            setGenerating(false);
        } else {
            console.log("Error posting metadata");
        }
    }

    return(
        <>
        <Container title="o. Devs" className={fadeTransition} >

            {authed && (
                <>
                    <h2>Generate Metadata</h2>

                    <p>Generate new metadata for given token id and re-upload a new Sigil to IPFS. <br /> Old metadata will be lost.</p>

                    <p style={{textAlign: "left"}}>
                    tokenId: <input style={{width:"40px"}} type="text" placeholder="1" onChange={(e) =>
                        setReservedTokenId(e.target.value)
                    }></input>
                    <br />
                    txHash: <input type="text" style={{width:"500px"}} placeholder="0x000..." onChange={(e) =>
                        setReservedTxHash(e.target.value)
                    }></input>
                    <br />
                    spell: <input maxLength={10} type="text" placeholder="ofmt58sn50" onChange={(e) =>
                        setReservedTokenSpell( e.target.value )
                    }></input>
                    <br />
                    <br />
                    <button className={generating && "nav loading" || "nav"} onClick={() =>
                        generateMetadata(reservedTokenSpell, parseInt(reservedTokenId), reservedtxHash)
                    }>Generate Metadata</button>
                </p>
                <p>
                {metadataLink && (
                    <a href={metadataLink} target="_blank">View New Metadata</a>
                )}
                </p>
                <br /><br/>
                <p>
                    <button className="nav" onClick={() => {
                        setAuthed(false);
                    }}>Logout</button>
                </p>
                </>
            ) || (
            <>
                pw: <input onChange={(e) => pwSetInput(e.target.value)} type="password" />
                <button onClick={testAuth}>Login</button>
            </>
            )}

        </Container>
        </>
    );
}
