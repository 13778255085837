import * as React from "react";
import { useRef, useState, useEffect } from "react";
import { Router, useLocation, Route, Link, Switch, BaseLocationHook } from "wouter";

import Wallet from './components/Wallet';
import AudioPlayer from "./components/AudioPlayer";

import Index from './components/pages/00_Index';
import Initiation from './components/pages/01_Initiation';
import Banishing from './components/pages/02_Banishing';
import Logos from './components/pages/03_Logos';
import Incantation from './components/pages/04_Incantation';
import Seal from './components/pages/05_Seal';
import Casting from './components/pages/06_Casting';
import Sigil from './components/pages/07_Sigil';

import Devs from './components/Devs';

import "./index.css";

export default function () {

    const [location, setLocation] = useState("/");
    const [word, setWord] = useState("");
    const [wordEncoded, setWordEncoded] = useState("");
    const wallet = useRef<any>();
    const [mintTx, setMintTx] = useState();
    const [account, setAccount] = useState({});
    const [mute, setMute] = useState(false);

    const reset = () => {
        setWords("", "");
        setMintTx(undefined);
    }

    if (!process.env.DEVELOPMENT) {
        useEffect(() => {
            window.addEventListener('beforeunload', alertUser);
            return () => {
                window.removeEventListener('beforeunload', alertUser);
            }
        }, []);
        const alertUser = (e:any) => {
            if (currentLocation() != "/") {
                e.preventDefault();
                e.returnValue = '';
            }
        }
    }

    useEffect(() => {
        if (process.env.DEVELOPMENT) {
            const localWord = localStorage.getItem("word");
            const localWordEncoded = localStorage.getItem("wordEncoded");
            if (localWord) setWord(localWord);
            if (localWordEncoded) setWordEncoded(localWordEncoded);
        }
        else {
            if (currentLocation() != "/") {
                navigate("/");
            }
        }
        reset();
    }, []);

    useEffect(() => {
        // localStorage.setItem("word", word);
        if (word && process.env.DEVELOPMENT)
            console.log("<App> word:", word)
    }, [word]);

    useEffect(() => {
        // localStorage.setItem("wordEncoded", wordEncoded);
        if (wordEncoded && process.env.DEVELOPMENT)
            console.log("<App> wordEncoded:", wordEncoded)
    }, [wordEncoded]);

    const setWords = (word:string, wordEncoded:string) => {
        setWord(word);
        setWordEncoded(wordEncoded);
    }

    // returns the current hash location in a normalized form
    // (excluding the leading '#' symbol)
    const currentLocation = () => {
        return window.location.hash.replace(/^#/, "") || "/";
    };

    const navigate = (to:string) => (window.location.hash = to);

    const useHashLocation:BaseLocationHook = () => {
        const [loc, setLoc] = useState(currentLocation());
        useEffect(() => {
            // this function is called whenever the hash changes
            const handler = () => setLoc(currentLocation());
            setLocation(loc);
            // scroll to top of page
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            // subscribe to hash changes
            window.addEventListener("hashchange", handler);
            return () => window.removeEventListener("hashchange", handler);
        }, []);
        return [loc, navigate];
    };

    const onConnected = (account:any) => {
        setAccount(account);
    }

    return (
    <>
    <Router hook={useHashLocation}>
        <Route path="/i">
            <Initiation />
        </Route>
        <Route path="/ii">
            <Banishing />
        </Route>
        <Route path="/iii">
            <Logos onWordChange={setWords} />
        </Route>
        <Route path="/iv">
            <Incantation word={word}
                onIncantBegin={() => {
                    setMute(true);
                }}
                onIncantEnd={() => {
                    setMute(false);
                }}
            />
        </Route>
        <Route path="/v">
            <Seal wordEncoded={wordEncoded} />
        </Route>
        <Route path="/vi">
            <Casting
                wordEncoded={wordEncoded}
                mintTx={mintTx}
                castSigil={() => {
                    if(wallet.current) {
                        wallet.current.castSigil(account, wordEncoded);
                    }
                }}
                account={account}
            />
        </Route>
        <Route path="/vii">
            <Sigil mintTx={mintTx} />
        </Route>
        <Route path="/devs">
            <Devs
                regenereateMetadata={async (spell:string, id:number, tx:string) => {
                    if(wallet.current) {
                        return await wallet.current.regenereateMetadata(spell, id, tx);
                    }
                }}
            />
        </Route>
        <Route>
            <Index />
        </Route>
    </Router>

    <AudioPlayer play={false} mute={mute} currentLocation={currentLocation()} />

    <Wallet ref={wallet} onConnected={onConnected} onMint={(mintTx:any) => { setMintTx(mintTx); }} />

    {location != "/" && (
        <a className="nav reset" onClick={(e) => {
            const c = confirm("Are you sure you want to reset?");
            if (c) reset();
            else e.preventDefault();
        }} href="/#/" ><span className="glyph">⟲</span> Reset</a>
    )}

  </>
  );
};